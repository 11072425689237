import React, { useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Button from './components/Button';
import Calculator from './components/Calculator';
import logo from './logo.svg';
import './App.css';

function App() {
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={
              <div className="App">
                <header className="App-header">
          
                  <img src={logo} className="App-logo" alt="logo" />
          
                  <p> Edit <code>src/App.js</code> and save to reload. </p>
          
                  <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn React
                  </a>
          
                  <p>You clicked {count} times</p>
                  <Button onClick={handleClick}>Click me</Button>
          
                </header>
              </div>
        } />
        <Route path="/calc" element={<Calculator />} />
      </Routes>
    </HashRouter>
  );
}
export default App;
